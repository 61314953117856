import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout>
    <div
      css={theme => css`
        margin: ${theme.constants.pageMargin};
        text-align: center;
        min-height: calc(
          100vh - ${theme.constants.infoFullHeight / 2}px -
            ${theme.constants.footerHeight}px -
            ${theme.constants.headerHeight}px
        );
        justify-content: center;
        display: flex;
        flex-direction: column;

        @media (max-width: 600px) {
          margin: ${theme.constants.mediumpageMargin};
        }

        @media (max-width: 360px) {
          margin: ${theme.constants.smallpageMargin};
        }
      `}
    >
      <h1
        css={theme => css`
          font-size: ${theme.constants.semiFontSize}px;
          font-weight: ${theme.constants.medium};

          @media (max-width: 880px) {
            font-size: ${theme.constants.mediumFontSize}px;
            margin-bottom: 30px;
          }
        `}
      >
        NOT FOUND
      </h1>
      <p
        css={theme => css`
          font-size: ${theme.constants.mediumFontSize}px;
          font-weight: ${theme.constants.light};

          @media (max-width: 880px) {
            font-size: ${theme.constants.smallFontSize}px;
          }
        `}
      >
        You just hit a route that doesn&#39;t exist... the sadness.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
